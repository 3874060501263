import revive_payload_client_4sVQNw7RlN from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/www-root/data/www/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/www-root/data/www/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/var/www/www-root/data/www/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/var/www/www-root/data/www/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_b5LO69U7Vr from "/var/www/www-root/data/www/app/node_modules/nuxt-sanctum-auth/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/www-root/data/www/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import error_handler_VFH3VvK7yG from "/var/www/www-root/data/www/app/plugins/error-handler.js";
import methods_tZzuw72moZ from "/var/www/www-root/data/www/app/plugins/methods.js";
import sentry_PZVGm6LH3G from "/var/www/www-root/data/www/app/plugins/sentry.js";
import vuex_owYp5qnaH8 from "/var/www/www-root/data/www/app/plugins/vuex.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_UYiXMU8ZyN,
  plugin_b5LO69U7Vr,
  i18n_yfWm7jX06p,
  error_handler_VFH3VvK7yG,
  methods_tZzuw72moZ,
  sentry_PZVGm6LH3G,
  vuex_owYp5qnaH8
]